import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Button } from 'react-bootstrap';

const HSRGuidesGameSystemsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Achievement Tracker</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_achie.png"
            alt="Achievement Tracker"
          />
        </div>
        <div className="page-details">
          <h1>Achievement Tracker</h1>
          <h2>
            Want to track your achievement hunt progress? This tool is for you!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Achievement Tracker" />
        <StaticImage
          src="../../../images/starrail/generic/trackerachie.jpg"
          alt="Tracker"
        />
        <p>
          What started as an amazing sheet, was transformed into a fully working
          website that allows you to easily track your achievement progress and
          even compare it with other people! Full credits to Meow who created
          both the sheet and the website:
        </p>
        <OutboundLink
          href="https://stardb.gg/achievement-tracker"
          target="_blank"
        >
          <Button variant="primary">Achievement Tracker</Button>
        </OutboundLink>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGameSystemsPage;

export const Head: React.FC = () => (
  <Seo
    title="Achievement Tracker | Honkai: Star Rail | Prydwen Institute"
    description="Want to track your achievement hunt progress? This tool is for you!"
  />
);
